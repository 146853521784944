const decisionOptions = [
  {
    key: "go",
    text: "Go",
    value: "go",
  },
  {
    key: "no_go",
    text: "No Go",
    value: "no_go",
  },
  {
    key: "no_show",
    text: "No Show",
    value: "no_show",
  },
];

export default decisionOptions;
