import axios from "axios";

const apiURL = "https://csg-apollo-be.herokuapp.com";
// const apiURL = "http://localhost:8081";

const customAxios = axios.create({
  // baseURL: "http://localhost:8081",
  baseURL: "https://csg-apollo-be.herokuapp.com",
});

customAxios.interceptors.request.use(
  (config) => {
    const { origin } = new URL(config.baseURL);
    const allowedOrigins = [apiURL];
    const token = localStorage.getItem("token");

    if (allowedOrigins.includes(origin)) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default customAxios;
