import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import {
  Header,
  Grid,
  Segment,
  Divider,
  List,
  Card,
  Button,
	Progress,
	GridColumn,
} from "semantic-ui-react";
import moment from "moment";
import { fetchClient, editClient } from "../../actions/client";
import LoadingPage from "./LoadingPage";
import SiteHeader from "./Header";
import Footer from "./Footer";
import userOptions from "../userOptions";
import impactMapping from "../impactMapping";
import history from "../../history";
import DecisionPage from "./DecisionPage";

class ScreenerTwo extends React.Component {
	impactMapping = impactMapping

	state = {
			user_assigned: null,
			isAllowedUser: false,
			hasUser: false,
			hasDecision: false,
		};
	
	async componentDidMount() {
		const { id } = this.props.match.params;
		// console.log(id);
		await this.props.fetchClient(id);

		this.setState({user_assigned: this.props.client.client.assigned_to})
		// console.log(JSON.parse(localStorage.getItem("username")))
		// console.log(this.props.client.client.assigned_to)

        if (this.props.client.client.assigned_to!==JSON.parse(localStorage.getItem("username"))) {
			history.push(`/screen_one/${id}`)
		} else {
            if (this.props.client.client.assigned_to) {
                this.setState({hasUser: true})
            }
    
            if (this.props.client.client.assigned_to===JSON.parse(localStorage.getItem("username"))) {
                this.setState({isAllowedUser: true})
            }
    
            if (this.props.client.decision) {
                this.setState({hasDecision: true})
            }
        }
		
	}

	handleUserChange = (e, { name, value }) => {
		if (value==="") {
			this.setState({ [name]: null })
		} else {
			this.setState({ [name]: value })
		}
		};



	onSubmitUserChange = async (id) => {
		try {
			const response = await this.props.editClient(id, { assigned_to: this.state.user_assigned, assigned_at: moment() });
			
		} catch (err) {
			console.log(err);
		}
	};

	getFilterKeys(filter, myobject) {
    var filtered = {};
    for(var key in myobject) {
      if(key.endsWith(filter)) {
         filtered[key] = myobject[key];
      }
    }
    return filtered;
  }

  getPreFilterKeys(filter, myobject) {
    var filtered = {};
    for(var key in myobject) {
      if(key.startsWith(filter)) {
         filtered[key] = myobject[key];
      }
    }
    return filtered;
  }

  keepFilterValues(min_threshold, max_threshold, myobject) {

    // Sort the object by descending before filtering it
    const sortable = Object.fromEntries(Object.entries(myobject).sort(([,a],[,b]) => b-a));

    return _.flow([
        Object.entries,
        arr => arr.filter(([key, value]) => ((value >= min_threshold) & (value <= max_threshold))),
        Object.fromEntries
      ])(sortable);

  }

  renderSymptoms(client, min_intensity, max_intensity) {
    
    const result = this.keepFilterValues(min_intensity, max_intensity, this.getFilterKeys('intensity', client))
		
    if (Object.keys(result).length === 0) {
      return (<p>No body signals experienced.</p>)
    }


    const result_array = Object.entries(result)
    return result_array.map(symptom => { 
      const symptom_prefix = symptom[0].replace("_intensity", "")
			
			let red_bar_colour = false
			let yellow_bar_colour = false

      if (symptom[1] >= 3) {
				red_bar_colour = true
			} else {
				yellow_bar_colour = true
			}
			
      const symptom_string = symptom_prefix[0].toUpperCase() + symptom_prefix.substring(1).replaceAll("_", " ")

      return (<Card fluid key={symptom[0]}> <Card.Content><List.Item><p><strong>{symptom_string}: </strong> 
      {client[`${symptom_prefix}_frequency`]} for {client[`${symptom_prefix}_duration`] ? client[`${symptom_prefix}_duration`].toLowerCase() : ''}</p> 
      <Progress percent={symptom[1]*20} indicating error={red_bar_colour} warning={yellow_bar_colour}>{symptom[1]}</Progress> </List.Item></Card.Content></Card>)})

  }

  renderImpact(client) {
    const impact_list = ['decreased_productivity', 'difficulty_concentrating', 'unable_long_hours', 'easily_stressed_out', 'lose_patience_others',
                        'exhausted_end_of_day', 'interrupts_sleep', 'moody_and_irritable', 'restriction_daily_activities', 'hinders_exercise', 'unable_to_carry']

    const impact = _.pick(client, impact_list);
    const result = this.keepFilterValues(true, true, impact)

    if (Object.keys(result).length === 0) {
      return (<p>No impact experienced.</p>)
    }
    const result_array = Object.entries(result)
    return result_array.map(impact => {   
      
    //   const impact_string = impact[0][0].toUpperCase() + impact[0].substring(1).replaceAll("_", " ")

      return (<List.Item key={impact[0]}><p>{impactMapping[impact[0]]}</p> </List.Item>)})

  }

  

  renderTreatments(client) {
    const result = this.keepFilterValues(true, true, this.getPreFilterKeys('tried_', client))
    
    if (Object.keys(result).length === 0) {
      return (<p>No treatments tried.</p>)
    }

    const result_array = Object.entries(result)
    return result_array.map(treatment => { 
      
      const treatment_prefix = treatment[0].replace("tried_", "")
      const treatment_string = treatment_prefix[0].toUpperCase() + treatment_prefix.substring(1).replaceAll("_", " ")
      
      if (treatment_string === 'Na') {
        return (<List.Item key={treatment[0]}><p>Did not seek treatment.</p> </List.Item>) 
      } 
      return (<List.Item key={treatment[0]}><p>{treatment_string}</p> </List.Item>)})

  }

  renderAims(client) {
    const result = this.keepFilterValues(true, true, this.getPreFilterKeys('achieve_', client))
    
    if (Object.keys(result).length === 0) {
      return (<p></p>)
    }

    const result_array = Object.entries(result)
    return result_array.map(achieve => { 
      
      const treatment_prefix = achieve[0].replace("achieve_", "")
      const treatment_string = treatment_prefix[0].toUpperCase() + treatment_prefix.substring(1).replaceAll("_", " ")
      
      return (<List.Item key={achieve[0]}><p>{treatment_string}</p> </List.Item>)})

  }


	userOptions = userOptions;

	render() {

		if (!this.props.client) {
				return <LoadingPage />;
			}
	
		const client = this.props.client.client
		return (
			<div>
				<SiteHeader />
				<Grid columns={3}  container stackable relaxed>
					<Grid.Row stretched>
							<Grid.Column width= {2} />
							<Grid.Column width={12}>
									<Segment>
											<Header as="h2">👨‍⚕️ Screening Details</Header>
											<Divider />
											<Card fluid>
													<Card.Content>
													<Grid columns={2}  container stackable relaxed divided>
															<Grid.Row>
																	<Grid.Column>
                                    <Header as="h3">📜 Details</Header>
																		<Divider />
																			<List relaxed size="large">


																					<List.Item>
																							<strong>First Name:</strong> {client.first_name}
																					</List.Item>
																					<List.Item>
																							<strong>Last Name:</strong> {client.last_name}
																					</List.Item>
                                                                                    <List.Item>
																							<strong>Age:</strong> {client.age}
																					</List.Item>
				
																			</List>
																	</Grid.Column>
																	<GridColumn>
                                    <Header as="h3">🌍 Your Lifestyle</Header>
																		<Divider />
																			<List relaxed size="large">
																					
																					<List.Item>
																							<strong>Hours sitting per day: </strong>{client.hrs_sit_per_day}
																					</List.Item>
																					<List.Item>
																							<strong>Hours on mobile per day: </strong>{client.hrs_mobile_per_day}
																					</List.Item>
																					</List>
																	</GridColumn>
															</Grid.Row>
													</Grid>
											
	
							
									</Card.Content>
								</Card>
										
								<Divider />
								
								<Card fluid >
									<Grid.Row >
										<Segment><Header as="h3">Aim of Today's Screening</Header><Divider />
										<List bulleted size='large'>{this.renderAims(client)}
										{client.achieve_others ?  (<List.Item><p>{client.achieve_others}</p></List.Item>) : null }</List></Segment>
									</Grid.Row>
								</Card>
								
								<Divider />

								
								<Card fluid >
									<Grid.Row >
										<Segment style={{ background: "#F8C9CD" }}><Header as="h3">⚠️ Warning</Header><Divider /><List bulleted size='large'>{this.renderSymptoms(client, 3, 5)}</List></Segment>
									</Grid.Row>
								</Card>
								<Card fluid>
									<Grid.Row>
										<Segment style={{ background: "#FFEFBF" }}><Header as="h3">👀 To Closely Monitor</Header><Divider /><List bulleted size='large'>{this.renderSymptoms(client, 1, 2)}</List></Segment>	
									</Grid.Row>
								</Card>
								
							<Divider />
							<Grid columns={2} container stackable relaxed divided>
								<Grid.Row stretched>
									<Grid.Column>
										<Segment><Header as="h3">💥 Impact of Symptoms</Header><Divider /><List bulleted size='large'>{this.renderImpact(client)}</List></Segment>
									</Grid.Column>
									<Grid.Column>
										<Segment>
											<Header as="h3">💊 Treatments Tried</Header>
											<Divider />
											<List bulleted size='large'>
												{this.renderTreatments(client)}
												{client.tried_others ?  (<List.Item><p>{client.tried_others}</p></List.Item>) : <p/> }
											</List>
										</Segment>
          				</Grid.Column>
								</Grid.Row>
							</Grid>

							<Divider />
							<Segment>
							<Header as="h3">Next Steps:</Header>
              <Divider />
              <DecisionPage short isDisallowedUser={!this.state.hasUser} clientId={this.props.match.params.id} appointmentTime={client.appointment_time}/>
							</Segment>
							
							</Segment>
						</Grid.Column>
						<Grid.Column width= {2} />
												
					</Grid.Row>
					<Grid.Row centered>
					<div>
						<Button size='big' onClick={() => history.push(`/dashboard`)}>Back to Main Menu</Button> 
						<Button size='big' onClick={() => history.push(`/screen_one/${client.id}`)}>Back to Previous Step</Button>  
					</div>
					</Grid.Row>
					<Divider />
					
					</Grid>
					<Footer />
			</div>
		);
	}
}
    
	
const mapStateToProps = (state, ownProps) => {
  return {
    client: state.clients.[ownProps.match.params.id],
    current_user: state.auth.username
  };
};
    
export default connect(mapStateToProps, { fetchClient, editClient })(ScreenerTwo);
      
      


