const frequencyOptions = [
  {
    key: "come_go",
    text: "Comes and goes",
    value: "Comes and goes",
  },
  {
    key: "upon_activity",
    text: "Upon certain activity",
    value: "Upon certain activity",
  },
  {
    key: "constant",
    text: "Constant",
    value: "Constant",
  },
];

export default frequencyOptions;
