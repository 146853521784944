import React from "react";
import { connect } from "react-redux";
import {
  Container,
  Segment,
  Table,
  Pagination,
  Input,
  Header,
} from "semantic-ui-react";

import { fetchClients } from "../../actions/client";
import DashboardListItem from "./DashboardListItem";
import PageHeader from "./Header";
import Footer from "./Footer";
import LoadingPage from "./LoadingPage";

class DashboardList extends React.Component {
  state = {
    page_length: 50,
    activePage: 1,
    begin: 0,
    end: 50,
    filter: "",
    client_list: [],
    client_filter_list: [],
    scheduler_list: [],
    scheduler_filter_list: [],
    scheduler_full_list: [],
  };

  async componentDidMount() {
    await this.props.fetchClients();

    if (!this.props.showUser) {
      await this.setState({
        client_filter_list: this.props.clients,
        client_list: this.props.clients.slice(this.state.begin, this.state.end),
        scheduler_list: ["Not relevant"],
      });
    } else {
      // filter to show clients with a decision of Go only
      const result = this.props.clients.filter((client) => {
        if (client.decision) {
          return client.decision.decision === this.props.showUser;
        }
        return false;
      });

      await this.setState({
        scheduler_full_list: result,
        scheduler_filter_list: result,
        scheduler_list: result.slice(this.state.begin, this.state.end),
        client_list: ["Not relevant"],
      });
    }
  }

  handleChange = async (event) => {
    await this.setState({ filter: event.target.value });

    if (this.props.showUser) {
      const { filter, scheduler_full_list } = this.state;
      const lowercasedFilter = filter.toLowerCase();
      const filteredData = scheduler_full_list.filter((item) => {
        return Object.keys(item).some(
          (key) =>
            typeof item[key] === "string" &&
            item[key].toLowerCase().includes(lowercasedFilter)
        );
      });
      await this.setState({ scheduler_filter_list: filteredData });
      // console.log(filteredData);
      await this.setState({
        scheduler_list: this.state.scheduler_filter_list.slice(
          0,
          this.state.page_length
        ),
        activePage: 1,
      });
    } else {
      const { filter } = this.state;
      const lowercasedFilter = filter.toLowerCase();
      const filteredData = this.props.clients.filter((item) => {
        return Object.keys(item).some(
          (key) =>
            typeof item[key] === "string" &&
            item[key].toLowerCase().includes(lowercasedFilter)
        );
      });
      await this.setState({ client_filter_list: filteredData });
      // console.log(filteredData);
      await this.setState({
        client_list: this.state.client_filter_list.slice(
          0,
          this.state.page_length
        ),
        activePage: 1,
      });
    }
  };

  btnClick = async (event, data) => {
    await this.setState({ activePage: data.activePage });
    await this.setState({
      begin:
        this.state.activePage * this.state.page_length - this.state.page_length,
    });
    await this.setState({
      end: this.state.activePage * this.state.page_length,
    });

    if (!this.props.showUser) {
      await this.setState({
        client_list: this.state.client_filter_list.slice(
          this.state.begin,
          this.state.end
        ),
      });
    } else {
      await this.setState({
        scheduler_list: this.state.scheduler_filter_list.slice(
          this.state.begin,
          this.state.end
        ),
      });
    }
  };

  renderList() {
    // Once Clients API call is returned
    if (this.props.clients) {
      // Check if we should used the filtered results
      if (this.props.showUser) {
        const result = this.state.scheduler_list;

        // Handle case when page has loaded but filter seach has no results.
        if (result.length === 0) {
          return (
            <Table.Row>
              <Table.Cell>No results found.</Table.Cell>
            </Table.Row>
          );
        }
        // Render the filtered result
        return result.map((client) => {
          return (
            <DashboardListItem
              className="item"
              viewType={this.props.viewType}
              key={client.id}
              {...client}
            />
          );
        });
      }

      // If no need to filter results, just sort and render everything
      // Handle case when page has loaded but filter seach has no results.
      if (this.state.client_list.length === 0) {
        return (
          <Table.Row>
            <Table.Cell>No results found.</Table.Cell>
          </Table.Row>
        );
      }

      return this.state.client_list.map((client) => {
        return (
          <DashboardListItem
            className="item"
            viewType={this.props.viewType}
            key={client.id}
            {...client}
          />
        );
      });
    }
  }

  render() {
    return (
      <Container>
        {this.props.isLoading ||
        (this.state.client_list.length === 0 &&
          this.state.scheduler_list.length === 0) ? (
          <LoadingPage />
        ) : (
          <div>
            <PageHeader />
            <Header as="h2">
              {this.props.viewType === "scheduler"
                ? "📅 Scheduler Dashboard"
                : this.props.viewType === "screener"
                ? "👨‍⚕️ Screener Dashboard"
                : "👩‍💻 Admin Dashboard"}
            </Header>

            <Input
              icon="search"
              placeholder="Search..."
              value={this.state.filter}
              onChange={this.handleChange}
            />

            <Table celled padded selectable color="teal">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>ID</Table.HeaderCell>
                  <Table.HeaderCell>First Name</Table.HeaderCell>
                  <Table.HeaderCell>Last Name</Table.HeaderCell>
                  <Table.HeaderCell>Mobile</Table.HeaderCell>
                  <Table.HeaderCell>Assigned To</Table.HeaderCell>
                  {this.props.viewType === "scheduler" ? (
                    <Table.HeaderCell>Helm Code</Table.HeaderCell>
                  ) : (
                    <Table.HeaderCell>Decision</Table.HeaderCell>
                  )}
                  {this.props.viewType === "scheduler" ? (
                    <Table.HeaderCell>Payment Type</Table.HeaderCell>
                  ) : (
                    <Table.HeaderCell>Appointment Time</Table.HeaderCell>
                  )}
                </Table.Row>
              </Table.Header>
              <Table.Body>{this.renderList()}</Table.Body>
            </Table>
            <br />
            <br />

            <Pagination
              defaultActivePage={1}
              totalPages={
                this.props.showUser
                  ? Math.ceil(
                      this.state.scheduler_filter_list.length /
                        this.state.page_length
                    )
                  : Math.ceil(
                      this.state.client_filter_list.length /
                        this.state.page_length
                    )
              }
              onPageChange={this.btnClick}
            />

            <Footer />
          </div>
        )}
      </Container>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    clients: state.clients.client_list,
    isLoading: state.clients.isLoading,
  };
};

export default connect(mapStateToProps, { fetchClients })(DashboardList);

// export default DashboardList;
