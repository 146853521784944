import React from "react";
import { connect } from "react-redux";
import { login } from "../actions/auth";

class AuthCheck extends React.Component {
  constructor(props) {
    super(props);
    if (
      this.props.isAuthenticated === false &&
      !!localStorage.getItem("username")
    ) {
      this.props.login(localStorage.getItem("username"));
    }
  }

  render() {
    return <div></div>;
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: !!state.auth.isLoggedIn,
});

export default connect(mapStateToProps, { login })(AuthCheck);
