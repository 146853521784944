import apollo from "../apis/apollo";
import history from "../history";
import { startLogout } from "./auth";

// export const fetchClients = () => async (dispatch) => {
//   try {
//     const response = await apollo.get("/api/pms");

//     dispatch({ type: "FETCH_CLIENTS", payload: response.data });
//   } catch (error) {
//     console.log(error);
//     localStorage.clear();
//     history.push("/login");
//     dispatch(startLogout());
//   }
// };

export const createDecision = (data) => async (dispatch) => {
  try {
    const response = await apollo.post("/api/pm/desc/create", { ...data });
    dispatch({ type: "CREATE_DECISION", payload: response.data });
    history.push("/dashboard");
  } catch (error) {
    dispatch(startLogout());
  }
};

export const fetchDecision = (client_id) => async (dispatch) => {
  try {
    const response = await apollo.get(`/api/pm/desc/${client_id}`);
    dispatch({ type: "FETCH_DECISION", payload: response.data });
  } catch (error) {
    dispatch(startLogout());
  }
};

export const editDecision =
  (client_id, decision_id, data) => async (dispatch) => {
    try {
      const response = await apollo.patch(`/api/pm/desc/${decision_id}`, data);
      dispatch({ type: "EDIT_DECISION", payload: response.data });
      history.go(0);
    } catch (error) {
      console.log(error);
      dispatch(startLogout());
    }
  };
