import React from "react";
import { connect } from "react-redux";
import {
  Header,
  Grid,
  Segment,
  Divider,
  List,
  Card,
  Dropdown,
  Button,
	GridColumn,
} from "semantic-ui-react";
import moment from "moment";
import { fetchClient, editClient } from "../../actions/client";
import LoadingPage from "./LoadingPage";
import SiteHeader from "./Header";
import Footer from "./Footer";
import DecisionPage from "./DecisionPage";
import userOptions from "../userOptions";
import history from "../../history";

class SchedulerView extends React.Component {
  state = {
    user_assigned: null,
    isAllowedUser: false,
    hasUser: false,
    hasDecision: false,
  };

  async componentDidMount() {
    const { id } = this.props.match.params;
    // console.log(id);
    await this.props.fetchClient(id);

    this.setState({user_assigned: this.props.client.client.assigned_to})
    // console.log(JSON.parse(localStorage.getItem("username")))
    // console.log(this.props.client.client.assigned_to)

    if (this.props.client.client.assigned_to) {
      this.setState({hasUser: true})
    }

    if (this.props.client.client.assigned_to===JSON.parse(localStorage.getItem("username"))) {
      this.setState({isAllowedUser: true})
    }

    if (this.props.client.decision) {
      this.setState({hasDecision: true})
    }
    
  }

  handleUserChange = (e, { name, value }) => {
    if (value==="") {
      this.setState({ [name]: null })
    } else {
      this.setState({ [name]: value })
    }
    };


  // submitUserChange = (e, { name, value }) => {
  //   this.props.fetchClient(this.props.match.params.id);
  //   this.setState({ [name]: value })};

  onSubmitUserChange = async (id) => {
    try {
      const response = await this.props.editClient(id, { assigned_to: this.state.user_assigned, assigned_at: moment() });
      
    } catch (err) {
      console.log(err);
    }
  };

  userOptions = userOptions;

  render() {

    if (!this.props.client) {
        return <LoadingPage />;
      }
  
      const client = this.props.client.client
    return (
      <div>
        <SiteHeader />
        <Grid columns={3}  container stackable relaxed>
          <Grid.Row stretched>
              <Grid.Column width= {2} />
								<Grid.Column width={12}>
									<Segment>
										<Header as="h2">📅  Scheduling Details</Header>
										<Divider />
										<Card fluid>
											<Card.Content>
											<Grid columns={2}  container stackable relaxed>
												<Grid.Row>
													<Grid.Column>
														<List relaxed size="large">
											
															<List.Item>
																<strong>Client ID:</strong> {client.id}
															</List.Item>
															<List.Item>
																<strong>Event:</strong> {client.event_id}
															</List.Item>
															<List.Item>
																<strong>First Name:</strong> {client.first_name}
															</List.Item>
															<List.Item>
																<strong>Last Name:</strong> {client.last_name}
															</List.Item>
															<List.Item>
																<strong>Email:</strong> {client.email}
															</List.Item>
														</List>
													</Grid.Column>
													<GridColumn>
														<List relaxed size="large">
															<List.Item>
																<strong>Mobile:</strong> {client.mobile}
															</List.Item>
															<List.Item>
																<strong>Age:</strong> {client.age}
															</List.Item>
															<List.Item>
																<strong>Gender:</strong> {client.gender}
															</List.Item>
															<List.Item>
																<strong>Hours sitting per day:</strong>{client.hrs_sit_per_day}
															</List.Item>
															<List.Item>
																<strong>Hours on mobile per day:</strong>{client.hrs_mobile_per_day}
															</List.Item>
                    				</List>
													</GridColumn>
												</Grid.Row>
											</Grid>
										<br/>
                    
										<Button
                      primary
                      floated="right"
                      // disabled={!(this.props.client.client.assigned_to === this.props.current_user)}
                      onClick={() => history.push(`/detail/edit/${client.id}`)}
                    >
                      Edit
                    </Button>
                  </Card.Content>
                </Card>
                
								<Divider />
                
								<Card fluid>
                  <Card.Content>
                    <Header as="h3">Case Assignment</Header>
                    <Divider />
                    <List relaxed size="small">
                      <List.Item>
                        <strong>Assigned To:</strong>{" "}
                        {client.user ? client.user.username : "N/A"}{" "}
                      </List.Item>
                      <List.Item>
                        <strong>Assigned At:</strong>{" "}
                        {client.assigned_at
                          ? moment(client.assigned_at).format(
                              "MMM Do, YYYY - hh:mm:ss a"
                            )
                          : "N/A"}
                      </List.Item>
                      <Divider />
                      <List.Item>
                        <label>Change User:</label>
                        <Dropdown
                          closeOnBlur={false}
                          value={this.state.user_assigned}
                          clearable
                          search
                          selection
                          placeholder="Set User"
                          name="user_assigned"
                          options={this.userOptions}
                          onChange={this.handleUserChange}
                        />
                      </List.Item>

                      <Button
                        floated="right"
                        positive
                        disabled={
                          this.props.client.client.assigned_to ===
                          this.state.user_assigned
                        }
                        onClick={() => this.onSubmitUserChange(client.id)}
                      >
                        Save
                      </Button>
                    </List>
                  </Card.Content>
                </Card>
              </Segment>


							<Segment>
							<Header as="h3">Next Steps: </Header>
              <Divider />
              <DecisionPage isDisallowedUser={!this.state.hasUser} clientId={this.props.match.params.id} />
							</Segment>


            </Grid.Column>
            <Grid.Column width= {2} />
						
          </Grid.Row>

					<Grid.Row centered>
        		<Button size='big' onClick={() => history.push("/scheduler")}>Back to Main Menu</Button>  
        </Grid.Row>
				</Grid>
				<Footer />
      </div>
    );
  }
}





const mapStateToProps = (state, ownProps) => {
    return {
      client: state.clients.[ownProps.match.params.id],
      current_user: state.auth.username
    };
  };
  
  export default connect(mapStateToProps, { fetchClient, editClient })(SchedulerView);
  
  