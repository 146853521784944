import React from "react";
import { Icon, Container } from "semantic-ui-react";
import apollo from "../apis/apollo";
import ClientForm from "./ClientForm";

class Homepage extends React.Component {
  state = { showSuccess: false, successUser: null, isSubmitting: false };

  onFormSubmit = async (term) => {
    try {
      // console.log(term);
      this.setState({isSubmitting: true })
      const response = await apollo.post("/api/pm/create", { ...term });
      // console.log("The response is:");
      // console.log(response);
      if (response.status === 200) {
        const { first_name } = term;
        this.setState({ showSuccess: true, successUser: first_name, isSubmitting: false });
      }
    } catch (err) {
      console.log(err.response.data.error);
    }
  };

  render() {
    return (
      <div>
        
        {this.state.showSuccess ? (
          <Container text>
            <br />
            <div className="ui big success message">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Icon
                  fitted
                  color="teal"
                  className="check circle"
                  size="massive"
                />
              </div>
              <br />
              <div className="header">Hold Tight!</div>
              <br />
              <p>
                Thanks {this.state.successUser}! We’ve received your form. The
                next step will be to get your spine checked at the screening
                station to understand your results.
              </p>

              <p>You can now close this page. See you soon!</p>
            </div>
          </Container>
        ) : (
          <ClientForm onFormSubmit={this.onFormSubmit} api_isSubmitting={this.state.isSubmitting}/>
        )}
      </div>
    );
  }
}

export default Homepage;
