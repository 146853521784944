const bodysignalsOptions = [
  {
    key: "neck_pain",
    text: "Neck Pain",
    value: "neck_pain",
  },
  {
    key: "shoulder_stiffness",
    text: "Shoulder Stiffness",
    value: "shoulder_stiffness",
  },
  {
    key: "headaches",
    text: "Headaches / Migraines",
    value: "headaches",
  },
  {
    key: "ringing_in_ears",
    text: "Ringing In Ears",
    value: "ringing_in_ears",
  },
  {
    key: "dizziness",
    text: "Dizziness / Giddiness",
    value: "dizziness",
  },
  {
    key: "fatigue",
    text: "Fatigue / Tiredness",
    value: "fatigue",
  },
  {
    key: "numbness_tingling",
    text: "Numbness / Tingling In Hands",
    value: "numbness_tingling",
  },
  {
    key: "hand_pain",
    text: "Hand Pain",
    value: "hand_pain",
  },
  {
    key: "low_back_pain",
    text: "Low Back Pain",
    value: "low_back_pain",
  },
  {
    key: "leg_pain",
    text: "Leg Pain",
    value: "leg_pain",
  },
  {
    key: "leg_numbness",
    text: "Leg Numbness / Weakness",
    value: "leg_numbness",
  },
  {
    key: "hip_pain",
    text: "Hip Pain",
    value: "hip_pain",
  },
  {
    key: "bladder_trouble",
    text: "Bladder Trouble",
    value: "bladder_trouble",
  },
  {
    key: "constipation_diarrhoea",
    text: "Constipation / Diarrhoea",
    value: "constipation_diarrhoea",
  },
  {
    key: "sciatica",
    text: "Sciatica (shooting pain down the legs",
    value: "sciatica",
  },
  {
    key: "insomnia",
    text: "Insomnia / Sleeping Problems",
    value: "insomnia",
  },
  {
    key: "bloating",
    text: "Bloating",
    value: "bloating",
  },
  {
    key: "sinusitis_allergies",
    text: "Sinusitis / Allergies",
    value: "sinusitis_allergies",
  },
  {
    key: "asthma",
    text: "Asthma",
    value: "asthma",
  },
  {
    key: "irritability_anxiety",
    text: "Irritability / Anxiety",
    value: "irritability_anxiety",
  },
];

export default bodysignalsOptions;
