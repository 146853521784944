import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
// import expensesReducer from '../reducers/expenses';
// import filtersReducer from '../reducers/filters';
import authReducer from "../reducers/auth";
import clientReducer from "../reducers/clientReducer";
import decisionReducer from "../reducers/decisionReducer";
import paymentReducer from "../reducers/paymentReducer";
import reportReducer from "../reducers/reportReducer";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = () => {
  // Store creation

  const store = createStore(
    combineReducers({
      reports: reportReducer,
      decision: decisionReducer,
      payments: paymentReducer,
      clients: clientReducer,
      auth: authReducer,
    }),
    composeEnhancers(applyMiddleware(thunk))
  );
  return store;
};

export default configureStore;
