import apollo from "../apis/apollo";

export const getPayments = () => async (dispatch) => {
  try {
    const response = await apollo.get("/api/payment");
    if (response.status === 200) {
      dispatch({ type: "FETCH_PAYMENTS", payload: response.data });
    }
  } catch (err) {
    console.log("There was an error getting payment options:");
    // console.log(err.response.data);
  }
};
