const INITIAL_STATE = {
  decision: null,
};

const decisionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "FETCH_DECISION":
      return {
        ...state,
        decision: action.payload.decision,
      };
    case "CREATE_DECISION":
      return { ...state, [action.payload.id]: action.payload };
    case "EDIT_DECISION":
      return { ...state, [action.payload.id]: action.payload };
    default:
      return state;
  }
};

export default decisionReducer;
