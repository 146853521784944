const durationOptions = [
  {
    key: "less_1_mth",
    text: "Less than 1 month",
    value: "Less than 1 month",
  },
  {
    key: "more_1_mth",
    text: "More than 1 month",
    value: "More than 1 month",
  },
  {
    key: "more_1_yr",
    text: "More than 1 year",
    value: "More than 1 year",
  },
];

export default durationOptions;
