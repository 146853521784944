import React from "react";
import { connect } from "react-redux";
import { Header, Grid, Segment, Divider, List, Progress, Card, Dropdown, Button } from "semantic-ui-react";
import _ from "lodash";
import moment from "moment";
import { fetchClient, editClient, deleteClient } from "../../actions/client";
import LoadingPage from "./LoadingPage";
import SiteHeader from "./Header"
import Footer from "./Footer";
import DecisionPage from "./DecisionPage";
import userOptions from "../userOptions";
import history from "../../history";




class ViewClientPage extends React.Component {

  state = {user_assigned: null, isAllowedUser: false, hasUser:false, hasDecision: false }

  async componentDidMount() {
    const { id } = this.props.match.params;
    // console.log(id);
    await this.props.fetchClient(id);

    this.setState({user_assigned: this.props.client.client.assigned_to})
    // console.log(JSON.parse(localStorage.getItem("username")))
    // console.log(this.props.client.client.assigned_to)

    if (this.props.client.client.assigned_to) {
      this.setState({hasUser: true})
    }

    if (this.props.client.client.assigned_to===JSON.parse(localStorage.getItem("username"))) {
      this.setState({isAllowedUser: true})
    }

    if (this.props.client.decision) {
      this.setState({hasDecision: true})
    }
    
  }

  handleUserChange = (e, { name, value }) => {
    if (value==="") {
      this.setState({ [name]: null })
    } else {
      this.setState({ [name]: value })
    }
    };


  // submitUserChange = (e, { name, value }) => {
  //   this.props.fetchClient(this.props.match.params.id);
  //   this.setState({ [name]: value })};

  onSubmitUserChange = async (id) => {
    try {
      const response = await this.props.editClient(id, { assigned_to: this.state.user_assigned, assigned_at: moment() });
      
    } catch (err) {
      console.log(err);
    }
  };

  onDeleteClient = async (id) => {
    try {
      if (this.state.isAllowedUser) {
        await this.props.deleteClient(id);
      }
    } catch (err) {
      console.log(err);
    }
  };

  getFilterKeys(filter, myobject) {
    var filtered = {};
    for(var key in myobject) {
      if(key.endsWith(filter)) {
         filtered[key] = myobject[key];
      }
    }
    return filtered;
  }

  getPreFilterKeys(filter, myobject) {
    var filtered = {};
    for(var key in myobject) {
      if(key.startsWith(filter)) {
         filtered[key] = myobject[key];
      }
    }
    return filtered;
  }

  keepFilterValues(threshold, myobject) {

    // Sort the object by descending before filtering it
    const sortable = Object.fromEntries(Object.entries(myobject).sort(([,a],[,b]) => b-a));

    return _.flow([
        Object.entries,
        arr => arr.filter(([key, value]) => value >= threshold),
        Object.fromEntries
      ])(sortable);

  }

  renderSymptoms(client) {
    
    const result = this.keepFilterValues(1, this.getFilterKeys('intensity', client))

    if (Object.keys(result).length === 0) {
      return (<p>No body signals experienced.</p>)
    }


    const result_array = Object.entries(result)
    return result_array.map(symptom => { 
      const symptom_prefix = symptom[0].replace("_intensity", "")
      
      const symptom_string = symptom_prefix[0].toUpperCase() + symptom_prefix.substring(1).replaceAll("_", " ")

      return (<Card fluid key={symptom[0]}> <Card.Content><List.Item><p><strong>{symptom_string}: </strong> 
      {client[`${symptom_prefix}_frequency`]} for {client[`${symptom_prefix}_duration`]}</p> 
      <Progress percent={symptom[1]*20} indicating error>{symptom[1]}</Progress> </List.Item></Card.Content></Card>)})

  }

  renderImpact(client) {
    const impact_list = ['decreased_productivity', 'difficulty_concentrating', 'unable_long_hours', 'easily_stressed_out', 'lose_patience_others',
                        'exhausted_end_of_day', 'interrupts_sleep', 'moody_and_irritable', 'restriction_daily_activities', 'hinders_exercise', 'unable_to_carry']

    const impact = _.pick(client, impact_list);
    const result = this.keepFilterValues(true, impact)

    if (Object.keys(result).length === 0) {
      return (<p>No impact experienced.</p>)
    }
    const result_array = Object.entries(result)
    return result_array.map(impact => { 
      
      
      const impact_string = impact[0][0].toUpperCase() + impact[0].substring(1).replaceAll("_", " ")

      return (<List.Item key={impact[0]}><p>{impact_string}</p> </List.Item>)})

  }

  

  renderTreatments(client) {
    const result = this.keepFilterValues(true, this.getPreFilterKeys('tried_', client))
    
    if (Object.keys(result).length === 0) {
      return (<p>No treatments tried.</p>)
    }

    const result_array = Object.entries(result)
    return result_array.map(treatment => { 
      
      const treatment_prefix = treatment[0].replace("tried_", "")
      const treatment_string = treatment_prefix[0].toUpperCase() + treatment_prefix.substring(1).replaceAll("_", " ")
      
      if (treatment_string === 'Na') {
        return (<List.Item key={treatment[0]}><p>Did not seek treatment.</p> </List.Item>) 
      } 
      return (<List.Item key={treatment[0]}><p>{treatment_string}</p> </List.Item>)})

  }


  renderAims(client) {
    const result = this.keepFilterValues(true, true, this.getPreFilterKeys('achieve_', client))
    
    if (Object.keys(result).length === 0) {
      return (<p></p>)
    }

    const result_array = Object.entries(result)
    return result_array.map(achieve => { 
      
      const treatment_prefix = achieve[0].replace("achieve_", "")
      const treatment_string = treatment_prefix[0].toUpperCase() + treatment_prefix.substring(1).replaceAll("_", " ")
      
      return (<List.Item key={achieve[0]}><p>{treatment_string}</p> </List.Item>)})

  }




  compare( a, b ) {
		if ( a.key < b.key ){
		  return -1;
		}
		if ( a.key > b.key ){
		  return 1;
		}
		return 0;
	  }

	userOptions = userOptions.sort(this.compare);
  
  render() {
    // console.log(this.props.client.id);

    if (!this.props.client) {
      return <LoadingPage />;
    }

    const client = this.props.client.client
    

    return (<div>
      <SiteHeader />
      <Grid columns={2} divided container stackable relaxed>
        <Grid.Row stretched>
          <Grid.Column>
            <Segment>
              <Header as="h2">👩‍💻 Admin Details</Header>
              <Divider />
              <Card fluid ><Card.Content>
                <List relaxed size="large">
                  <List.Item><strong>Client ID:</strong> {client.id}</List.Item>
                  <List.Item><strong>Event:</strong> {client.event_id}</List.Item>
                  <List.Item><strong>First Name:</strong> {client.first_name}</List.Item>
                  <List.Item><strong>Last Name:</strong> {client.last_name}</List.Item>
                  <List.Item><strong>Email:</strong> {client.email}</List.Item>
                  <List.Item><strong>Mobile:</strong> {client.mobile}</List.Item>
                  <List.Item><strong>Age:</strong> {client.age}</List.Item>
                  <List.Item><strong>Gender:</strong> {client.gender}</List.Item>
                  <List.Item><strong>Hours sitting per day:</strong> {client.hrs_sit_per_day}</List.Item>
                  <List.Item><strong>Hours on mobile per day:</strong> {client.hrs_mobile_per_day}</List.Item>
                  <List.Item><strong>Created At:</strong> {client.created_at ? moment(client.created_at).format("MMM Do, YYYY - hh:mm:ss a") : 'N/A'}</List.Item>
                  <List.Item><strong>Appointment Time:</strong> {client.appointment_time ? moment(client.appointment_time).format("MMM Do, YYYY - hh:mm:ss a") : 'N/A'}</List.Item>
                </List>
                <Button primary floated='right' 
                // disabled={!(this.props.client.client.assigned_to===this.props.current_user)} 
                          onClick={()=>history.push(`/detail/edit/${client.id}`)}>Edit</Button>
              </Card.Content></Card>
              <Divider />
              <Card fluid ><Card.Content>
              <Header as="h3">Case Assignment</Header>
              <Divider />
                <List relaxed size="small">
                  <List.Item><strong>Assigned To:</strong> {client.user ? client.user.username : "N/A"} </List.Item>
                  <List.Item><strong>Assigned At:</strong> {client.assigned_at ? moment(client.assigned_at).format("MMM Do, YYYY - hh:mm:ss a") : 'N/A'}</List.Item>
                  <Divider />
                  <List.Item><label>Change User:</label>
                    <Dropdown
                      closeOnBlur={false}
                      value={this.state.user_assigned}
                      clearable
                      search
                      selection
                      placeholder="Set User"
                      name="user_assigned"
                      options={this.userOptions}
                      onChange={this.handleUserChange}
                    />
                  
                   </List.Item>
                  
                  <Button floated='right' 
                          positive 
                          disabled={this.props.client.client.assigned_to===this.state.user_assigned} 
                          onClick={()=>this.onSubmitUserChange(client.id)}>Save
                  </Button>
                </List>
              </Card.Content></Card>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment><Header as="h3">Body Signals</Header><Divider /><List>{this.renderSymptoms(client)}</List></Segment>
            <Segment><Header as="h3">Impact of Symptoms</Header><Divider /><List bulleted size='large'>{this.renderImpact(client)}</List></Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Divider />
      <Grid columns={2} divided container stackable relaxed>
        <Grid.Row stretched>
          <Grid.Column>
            
            <Segment>              
              <Header as="h3">Treatments Tried</Header>
              <Divider />
              <List bulleted size='large'>
                {this.renderTreatments(client)}
                {client.tried_others ?  (<List.Item><p>{client.tried_others}</p></List.Item>) : <p/> }
              </List>
            </Segment>

            <Segment><Header as="h3">Aim of Today's Screening</Header><Divider />
            <List bulleted size='large'>{this.renderAims(client)}
            {client.achieve_others ?  (<List.Item><p>{client.achieve_others}</p></List.Item>) : null }</List></Segment>
          
        
            
          </Grid.Column>
          <Grid.Column>
            <Segment 
              disabled={!this.state.hasUser} 
            >
              <Header as="h3">Next Steps: </Header>
              <Divider />
              <DecisionPage isDisallowedUser={!this.state.hasUser} clientId={this.props.match.params.id} appointmentTime={client.appointment_time} />

            </Segment>
            
          </Grid.Column>
          
        </Grid.Row>
        <Grid.Row>
        
        <Button primary size='big' onClick={() => history.push("/admin")}>Back to Admin Menu</Button>  
        
        
          
        </Grid.Row>
        
      </Grid>
      <Button
            floated = 'right' 
            size='medium'
            negative 
            disabled={!this.state.isAllowedUser}  
            onClick={() => this.onDeleteClient(this.props.match.params.id)}>Delete</Button>  
      
      <Footer/>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    client: state.clients.[ownProps.match.params.id],
    current_user: state.auth.username
  };
};

export default connect(mapStateToProps, { fetchClient, editClient, deleteClient })(ViewClientPage);

