import React from "react";
import { connect } from "react-redux";
import {
  Container,
  Segment,
  Table,
  Button,
  Header,
  Divider,
  Dropdown,
  Form,
} from "semantic-ui-react";
import PageHeader from "./Header";
import Footer from "./Footer";
import LoadingPage from "./LoadingPage";
import {
  getEventReport,
  getEventScreenerReport,
  getScreenerReport,
  getPaymentReport,
  getEventsList,
} from "../../actions/report";

class ReportPage extends React.Component {
  state = { isLoading: true, event_selected: "" };

  async componentDidMount() {
    await this.props.getEventsList();
    // await this.props.getEventReport();
    // await this.props.getEventScreenerReport();
    // await this.props.getScreenerReport();
    // await this.props.getPaymentReport();
    this.setState({ isLoading: false });
  }

  humanize(str) {
    var i,
      frags = str.split("_");
    for (i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(" ");
  }

  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  onSubmit = (event_id) => {
    try {
      // console.log(event_id);
      this.props.getEventReport(event_id);
      this.props.getEventScreenerReport(event_id);
      this.props.getScreenerReport(event_id);
      this.props.getPaymentReport(event_id);
    } catch (err) {
      console.log(err);
    }
  };

  renderTableHeader = (report_list) => {
    return (
      <Table.Header>
        <Table.Row>
          {Object.keys(report_list[0]).map((key) => (
            <Table.HeaderCell key={key}>{this.humanize(key)}</Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Header>
    );
  };
  renderReport = (report_list) => {
    return report_list.map((row_item, index) => {
      return (
        <Table.Row key={index}>
          {row_item.hasOwnProperty("event_name") ? (
            <Table.Cell> {row_item.event_name}</Table.Cell>
          ) : null}
          {row_item.hasOwnProperty("screener") ? (
            <Table.Cell> {row_item.screener}</Table.Cell>
          ) : null}
          {row_item.hasOwnProperty("event_date") ? (
            <Table.Cell> {row_item.event_date}</Table.Cell>
          ) : null}
          {row_item.hasOwnProperty("total_screened") ? (
            <Table.Cell> {row_item.total_screened}</Table.Cell>
          ) : null}
          {row_item.hasOwnProperty("go") ? (
            <Table.Cell> {row_item.go}</Table.Cell>
          ) : null}
          {row_item.hasOwnProperty("no_go") ? (
            <Table.Cell> {row_item.no_go}</Table.Cell>
          ) : null}
          {row_item.hasOwnProperty("no_show") ? (
            <Table.Cell> {row_item.no_show}</Table.Cell>
          ) : null}
          {row_item.hasOwnProperty("percentage_go") ? (
            <Table.Cell> {row_item.percentage_go}</Table.Cell>
          ) : null}
          {row_item.hasOwnProperty("payment_type") ? (
            <Table.Cell> {row_item.payment_type}</Table.Cell>
          ) : null}
          {row_item.hasOwnProperty("clients") ? (
            <Table.Cell> {row_item.clients}</Table.Cell>
          ) : null}
          {row_item.hasOwnProperty("family_parlay") ? (
            <Table.Cell> {row_item.family_parlay}</Table.Cell>
          ) : null}
        </Table.Row>
      );
    });
  };

  render() {
    return (
      <Container>
        {this.state.isLoading ? (
          <LoadingPage />
        ) : (
          <div>
            <PageHeader />
            <Header as="h1">Summary</Header>
            <Form>
              <Form.Field>
                <h3>Select an Event</h3>
                <Dropdown
                  value={this.state.event_selected}
                  placeholder="Event Name"
                  fluid
                  search
                  selection
                  clearable
                  name="event_selected"
                  options={this.props.event_list}
                  onChange={this.handleChange}
                />
              </Form.Field>
              <Button
                positive
                floated="right"
                onClick={() => this.onSubmit(this.state.event_selected)}
              >
                Generate
              </Button>
            </Form>
            <br />
            <Header as="h2">Event Report</Header>
            <Divider />
            <Table celled striped compact="very" color="teal" fixed>
              {this.props.event_report.length !== 0
                ? this.renderTableHeader(this.props.event_report)
                : null}
              <Table.Body>
                {this.props.event_report.length !== 0
                  ? this.renderReport(this.props.event_report)
                  : null}
              </Table.Body>
            </Table>
            <br />
            <br />
            <Header as="h2">Payment Report</Header>
            <Divider />
            <Table celled striped compact="very" color="teal" fixed>
              {this.props.payment_report.length !== 0
                ? this.renderTableHeader(this.props.payment_report)
                : null}
              <Table.Body>
                {this.props.payment_report.length !== 0
                  ? this.renderReport(this.props.payment_report)
                  : null}
              </Table.Body>
            </Table>

            <br />
            <br />
            <Header as="h2">Event and Screener Report</Header>
            <Divider />
            <Table celled striped compact="very" color="teal" fixed>
              {this.props.event_screener_report.length !== 0
                ? this.renderTableHeader(this.props.event_screener_report)
                : null}
              <Table.Body>
                {this.props.event_screener_report.length !== 0
                  ? this.renderReport(this.props.event_screener_report)
                  : null}
              </Table.Body>
            </Table>
            <br />
            <br />
            <Header as="h2">Screener Report</Header>
            <Divider />
            <Table celled striped compact="very" color="teal" fixed>
              {this.props.screener_report.length !== 0
                ? this.renderTableHeader(this.props.screener_report)
                : null}
              <Table.Body>
                {this.props.screener_report.length !== 0
                  ? this.renderReport(this.props.screener_report)
                  : null}
              </Table.Body>
            </Table>

            <Footer />
          </div>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    event_report: state.reports.event_report,
    event_screener_report: state.reports.event_screener_report,
    screener_report: state.reports.screener_report,
    payment_report: state.reports.payment_report,
    event_list: state.reports.event_list,
  };
};

export default connect(mapStateToProps, {
  getEventReport,
  getEventScreenerReport,
  getScreenerReport,
  getPaymentReport,
  getEventsList,
})(ReportPage);
