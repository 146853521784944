import React from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import history from "../history";
import DashboardList from "../components/dashboard/DashboardList";
import Homepage from "../components/Homepage";
import Login from "../components/Login";
import ViewClientPage from "../components/dashboard/ViewClientPage";
import SchedulerView from "../components/dashboard/SchedulerView";
import ScreenerOne from "../components/dashboard/ScreenerOne";
import ScreenerTwo from "../components/dashboard/ScreenerTwo";
import EditDetailsPage from "../components/dashboard/EditDetailsPage";
import AuthCheck from "../components/AuthCheck";
import ReportPage from "../components/dashboard/ReportPage";
import PrivateRoute from "./PrivateRoute.js";

// import PublicRoute from "./PublicRoute.js";

class AppRouter extends React.Component {
  render() {
    return (
      <Router history={history}>
        <div>
          <AuthCheck />
          <Switch>
            <Route path="/" exact component={Homepage} />
            <Route path="/login" exact component={Login} />
            <PrivateRoute
              path="/dashboard"
              exact
              component={DashboardList}
              viewType="screener"
            />
            <PrivateRoute
              path="/scheduler"
              exact
              component={DashboardList}
              showUser="go"
              viewType="scheduler"
            />
            <PrivateRoute
              path="/admin"
              exact
              component={DashboardList}
              viewType="admin"
            />
            <PrivateRoute path="/detail/:id" exact component={ViewClientPage} />
            <PrivateRoute
              path="/schedule/:id"
              exact
              component={SchedulerView}
            />
            <PrivateRoute
              path="/screen_one/:id"
              exact
              component={ScreenerOne}
            />
            <PrivateRoute
              path="/screen_two/:id"
              exact
              component={ScreenerTwo}
            />
            <PrivateRoute
              path="/detail/edit/:id"
              exact
              component={EditDetailsPage}
            />
            <PrivateRoute path="/report" exact component={ReportPage} />
            <Redirect to="/" />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default AppRouter;
