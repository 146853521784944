const genderOptions = [
  {
    key: "male",
    text: "Male",
    value: "m",
  },
  {
    key: "female",
    text: "Female",
    value: "f",
  },
];

export default genderOptions;
