import _ from "lodash";

const INITIAL_STATE = {
  isLoading: true,
  client_list: [],
};

const clientReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "FETCH_CLIENTS":
      //   return { ...state, ..._.mapKeys(action.payload.clients, "id") };
      return {
        ...state,
        client_list: action.payload.clients,
        isLoading: false,
      };
    case "FETCH_CLIENT":
      return {
        ...state,
        [action.payload.client.id]: action.payload,
      };
    case "CREATE_CLIENT":
      return { ...state, [action.payload.id]: action.payload };
    case "EDIT_CLIENT":
      return { ...state, [action.payload.id]: action.payload };
    case "DELETE_CLIENT":
      return _.omit(state, action.payload);

    default:
      return state;
  }
};

export default clientReducer;
