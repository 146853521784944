import React from "react";
import { Provider } from "react-redux";
import AppRouter from "../routers/AppRouter";
import configureStore from "../store/configureStore";

// import DashboardList from "./dashboard/DashboardList";
// import Homepage from "./Homepage";
// import Login from "./Login";
// import history from "../history";

class App extends React.Component {
  render() {
    const store = configureStore();
    return (
      <div className="ui container">
        <Provider store={store}>
          <AppRouter />
        </Provider>
      </div>
    );
  }
}

export default App;
