import React from "react";
import { connect } from "react-redux";
import {
  Dropdown,
  Accordion,
  Icon,
  Button,
  Form,
  Divider,
} from "semantic-ui-react";
// import moment from "moment";
// import SemanticDatepicker from "react-semantic-ui-datepickers";
import decisionOptions from "./decisionOptions";
import semgOptions from "./semgOptions";
import { getPayments } from "../../actions/payment";
import {
  fetchDecision,
  createDecision,
  editDecision,
} from "../../actions/decision";
import { editClient } from "../../actions/client";


class DecisionPage extends React.Component {
  state = {
    decisionFilled: false,
    decision: null,
    activeIndex: 1,
    isLoading: true,
    isSubmitting: false
  };
  decisionOptions = decisionOptions;
  semgOptions = semgOptions;

  async componentDidMount() {
    await this.props.getPayments();
    await this.props.fetchDecision(this.props.clientId);
    if (this.props.decision_store) {
      this.setState({ ...this.props.decision_store, decisionFilled: true });
    }

    // console.log(this.props.clientId);
    // console.log(this.props.user_id);
    // console.log(this.props.decision_store);
    // console.log(this.state);
    this.setState({ isLoading: false, isSubmitting: false });
  }



  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  handleChange = (e, { name, value }) =>
    this.setState({ [name]: value, decisionFilled: true });

  onInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value });
  };

  onFormSubmit = async (event) => {
    event.preventDefault();
    this.setState({ actioned_by: this.props.user_id, isSubmitting: true });

    if (!this.props.decision_store) {
      //   console.log("no prior decision... creating one.");

      // To add logic to fill in appointment if it is null
      // if (!this.props.appointmentTime) {
      //   await this.props.editClient(this.props.clientId, {
      //     appointment_time: moment(),
      //   });
      // }

      await this.props.createDecision({
        ...this.state,
        id: this.props.clientId,
        decision: this.state.decision,
        username: this.props.user_id,
      });
    } else {
      await this.props.editDecision(
        this.state.client_id,
        this.state.id,
        this.state
      );
    }
  };

  renderFamNookal = (key, placeholder, value) => {
    return (
      <Form.Field
        disabled={
          this.props.isDisallowedUser 
        }
      >
        <label>{placeholder}</label>

        <input
          name={key}
          disabled={
            this.props.isDisallowedUser 
          }
          value={value ? value : ""}
          onChange={this.onInputChange}
          type="number"
          pattern="[0-9]*"
          inputMode="numeric"
          placeholder={placeholder}
        />
      </Form.Field>
    );
  };

  renderPayment = (key, value) => {
    return (
      <Form.Field
        disabled={
          this.props.isDisallowedUser 
        }
      >
        <label>Payment Type</label>
        <Dropdown
          disabled={
            this.props.isDisallowedUser 
          }
          value={value}
          placeholder="Payment Type"
          fluid
          search
          selection
          name={key}
          options={this.props.payment_list}
          onChange={this.handleChange}
        />
      </Form.Field>
    );
  };

  render() {
    return (
      <div className="ui segment">
        <Form onSubmit={this.onFormSubmit} loading={this.state.isLoading}>
          <Form.Field required>
            <label>SEMG Score</label>
            <Dropdown
              disabled={this.props.isDisallowedUser}
              value={this.state.semg_score}
              placeholder="SEMG Score"
              fluid
              selection
              name="semg_score"
              options={this.semgOptions}
              onChange={this.handleChange}
            />
          </Form.Field>

          <Form.Field required>
            <label>Decision</label>
            <Dropdown
              disabled={this.props.isDisallowedUser}
              value={this.state.decision}
              placeholder="Decision"
              fluid
              search
              selection
              name="decision"
              options={this.decisionOptions}
              onChange={this.handleChange}
            />
          </Form.Field>
          <Divider />

          {this.props.short ? (
            <div />
          ) : (
            <div>
              <Form.Field
                inline
                disabled={
                  this.props.isDisallowedUser || !(this.state.decision === "go")
                }
              >
                <label>Helm Code</label>
                <input
                  name="nookal_client_id"
                  value={
                    this.state.nookal_client_id
                      ? this.state.nookal_client_id
                      : ""
                  }
                  onChange={this.onInputChange}
                  type="number"
                  pattern="[0-9]*"
                  inputMode="numeric"
                  placeholder="Helm Code"
                />
              </Form.Field>

              {this.renderPayment("payment_type", this.state.payment_type)}
              <br />
              <Form.Field>
                <Accordion styled fluid>
                  <Accordion.Title
                    active={this.state.activeIndex === 0}
                    index={0}
                    onClick={this.handleClick}
                  >
                    <Icon name="dropdown" />
                    Family Parlay
                  </Accordion.Title>
                  <Accordion.Content active={this.state.activeIndex === 0}>
                    {this.renderFamNookal(
                      "family_one",
                      "Fam 1 Helm Code",
                      this.state.family_one
                    )}
                    {this.renderPayment(
                      "family_one_pay",
                      this.state.family_one_pay
                    )}
                    <Divider />
                    {this.renderFamNookal(
                      "family_two",
                      "Fam 2 Helm Code",
                      this.state.family_two
                    )}
                    {this.renderPayment(
                      "family_two_pay",
                      this.state.family_two_pay
                    )}
                    <Divider />
                    {this.renderFamNookal(
                      "family_three",
                      "Fam 3 Helm Code",
                      this.state.family_three
                    )}
                    {this.renderPayment(
                      "family_three_pay",
                      this.state.family_three_pay
                    )}
                    <Divider />
                    {this.renderFamNookal(
                      "family_four",
                      "Fam 4 Helm Code",
                      this.state.family_four
                    )}
                    {this.renderPayment(
                      "family_four_pay",
                      this.state.family_four_pay
                    )}
                    <Divider />
                  </Accordion.Content>
                </Accordion>
                <br />
                <br />
              </Form.Field>
            </div>
          )}

          <Divider />
          <Form.Field>
            <Button
              size="large"
              fluid
              positive
              disabled={
                this.props.isDisallowedUser || !this.state.decisionFilled || this.state.isSubmitting
              }
            >
              Save
            </Button>
          </Form.Field>
        </Form>

        {/* <Button
          size="large"
          disabled={this.props.isDisallowedUser}
          onClick={() => history.go(0)}
        >
          Cancel
        </Button> */}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    payment_list: state.payments.payment_list,
    user_id: state.auth.username,
    decision_store: state.decision.decision,
  };
};

export default connect(mapStateToProps, {
  getPayments,
  fetchDecision,
  createDecision,
  editDecision,
  editClient,
})(DecisionPage);
