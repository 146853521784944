const impactMapping = {
  decreased_productivity: "Decreased productity",
  difficulty_concentrating: "Difficulty concentraing",
  unable_long_hours: "Unable to work for long hours",
  easily_stressed_out: "Easily stressed out",
  lose_patience_others: "Lose patience with others",
  exhausted_end_of_day: "Exhausted at the end of the day",
  interrupts_sleep: "Interrupts your sleep",
  moody_and_irritable: "Moody and/or irritable",
  restriction_daily_activities: "Restriction in daily activities",
  hinders_exercise: "Hinders ability to exercise or participate in sports",
  unable_to_carry: "Unable to carry children/heavy items for long periods",
};

export default impactMapping;
