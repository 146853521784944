import React from "react";
import { connect } from "react-redux";
import {
  Header,
  Grid,
  Segment,
  Divider,
  Message,
  Card,
  Dropdown,
  Button,
  CardContent,
  Form,
} from "semantic-ui-react";
import { fetchClient, editClient } from "../../actions/client";
import SiteHeader from "./Header";
import Footer from "./Footer";
import genderOptions from "../genderOptions";
import history from "../../history";

class EditDetailsPage extends React.Component {

  state = {event_id: "", first_name: "", last_name:"", 
            email:"", mobile: "", age: "", gender:"", 
            hrs_mobile_per_day: "", hrs_sit_per_day: "", 
            assigned_to: null, isLoading: true, isForbidden: true} ;

  async componentDidMount() {
    const { id } = this.props.match.params;
    await this.props.fetchClient(id);
    // console.log(this.props.client)
    const {event_id, first_name, last_name, email, mobile, age, gender, hrs_mobile_per_day, hrs_sit_per_day, assigned_to} = this.props.client.client
    this.setState({event_id, first_name, last_name, email, mobile, age, gender, hrs_mobile_per_day, hrs_sit_per_day, assigned_to, isLoading:false, 
      // isForbidden: !(assigned_to === this.props.current_user
      // allow all users to edit user details
      isForbidden: false})
    
    // console.log(this.state)
    
  }

  onInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value });
  };

  onFormSubmit = async (event) => {
    event.preventDefault();
    console.log(this.state);
    await this.props.editClient(this.props.match.params.id, this.state);
    
  };




  renderForm = () => {
    return (
      <div>
        
        <Form onSubmit={this.onFormSubmit} loading={this.state.isLoading} error={this.state.isForbidden} >
        <Message
            error
            header='Action Forbidden'
            content='You can only edit details if you are assigned to this client.'
        />
          <p>Details of client: {this.props.match.params.id}</p>
          <Form.Field>
            <label>Event</label>
            <input
                value={this.state.event_id}
              name="event_id"
              onChange={this.onInputChange}
              type="text"
              placeholder="Event"
              disabled={this.state.isForbidden}
            />
          </Form.Field>
          <Form.Group widths="equal">
          <Form.Field>
            <label>First name</label>
            <input
            value={this.state.first_name}
              name="first_name"
              onChange={this.onInputChange}
              type="text"
              placeholder="First Name"
              disabled={this.state.isForbidden}
            />
          </Form.Field>
          <Form.Field>
            <label>Last name</label>
            <input
              value={this.state.last_name}
              name="last_name"
              onChange={this.onInputChange}
              type="text"
              placeholder="Last Name"
              disabled={this.state.isForbidden}
            />
          </Form.Field>
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Field>
              <label>Email</label>
              <input
              value={this.state.email}
                name="email"
                onChange={this.onInputChange}
                type="text"
                placeholder="Email Address"
                disabled={this.state.isForbidden}
              />
            </Form.Field>

            <Form.Field>
              <label>Mobile</label>
              <input
              value={this.state.mobile}
                name="mobile"
                onChange={this.onInputChange}
                type="number"
                pattern="[0-9]*"
                inputMode="numeric"
                placeholder="Mobile Number"
                disabled={this.state.isForbidden}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Age</label>
              <input
              value={this.state.age}
                name="age"
                onChange={this.onInputChange}
                type="number"
                pattern="[0-9]*"
                inputMode="numeric"
                placeholder="Age"
                disabled={this.state.isForbidden}
              />
            </Form.Field>

            <Form.Field>
              <label>Gender</label>
              <Dropdown
              value={this.state.gender}
                placeholder="Gender"
                fluid
                selection
                name="gender"
                options={genderOptions}
                onChange={this.handleChange}
                disabled={this.state.isForbidden}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Hours seated per day</label>
              <input
              value={this.state.hrs_sit_per_day}
                name="hrs_sit_per_day"
                onChange={this.onInputChange}
                type="number"
                pattern="[0-9]*"
                inputMode="numeric"
                placeholder="Hrs spent sitting daily"
                disabled={this.state.isForbidden}
              />
            </Form.Field>

            <div className="field">
              <label>Hours on mobile per day</label>
              <input
              value={this.state.hrs_mobile_per_day}
                name="hrs_mobile_per_day"
                onChange={this.onInputChange}
                type="number"
                pattern="[0-9]*"
                inputMode="numeric"
                placeholder="Hrs on mobile daily"
                disabled={this.state.isForbidden}
              />
            </div>
          </Form.Group>
          <Divider />
          <CardContent extra textAlign="center">
            <Button.Group size="large">
                <Button as='a'
                onClick={() =>
                    history.push(`/screen_one/${this.props.match.params.id}`)
                }
                >
                Back to Screening
                </Button>
                <Button.Or />
                <Button positive disabled={this.state.isForbidden}>
                Save
                </Button>
            </Button.Group>
          </CardContent>
        </Form>
      </div>
    );
  };



  render() {
    // if (this.state.isLoading) {
    //     return <LoadingPage />
    // }

    return (
      <div>
        <SiteHeader />
        <Grid centered columns={1} container>
          <Grid.Row>
            <Grid.Column width="14">
              <Segment>
                <Header as="h3">Edit Details</Header>
                <Divider />
                <Card fluid>
                  <Card.Content>{this.renderForm()}</Card.Content>
                  
                </Card>
              </Segment>
            </Grid.Column>
            <Grid.Column />
          </Grid.Row>
        </Grid>

        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
    return {
      client: state.clients.[ownProps.match.params.id],
      current_user: state.auth.username
    };
  };

export default connect(mapStateToProps, { fetchClient, editClient })(EditDetailsPage);
