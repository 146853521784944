const INITIAL_STATE = {
  isLoggedIn: null,
  uesrname: null,
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        isLoggedIn: action.payload.auth,
        username: parseInt(action.payload.username),
      };
    case "LOGOUT":
      return { ...state, isLoggedIn: false, username: null };
    default:
      return state;
  }
};

export default authReducer;
