import React from "react";
import { Table } from "semantic-ui-react";
import moment from "moment";
import history from "../../history";

const handleClick = (id, viewType) => {
  if (viewType === "admin") {
    history.push(`/detail/${id}`);
  } else if (viewType === "scheduler") {
    history.push(`/schedule/${id}`);
  } else if (viewType === "screener") {
    history.push(`/screen_one/${id}`);
  } else {
    history.push(`/login`);
  }
};

const DashboardListItem = ({
  id,
  first_name,
  last_name,
  mobile,
  created_at,
  appointment_time,
  user,
  decision,
  viewType,
}) => (
  <Table.Row
    onClick={() => {
      handleClick(id, viewType);
    }}
  >
    <Table.Cell>{id}</Table.Cell>
    <Table.Cell>{first_name}</Table.Cell>
    <Table.Cell>{last_name}</Table.Cell>
    <Table.Cell>{mobile}</Table.Cell>
    <Table.Cell>{user ? user.username : null}</Table.Cell>

    {viewType === "scheduler" ? (
      <Table.Cell>{decision ? decision.nookal_client_id : null}</Table.Cell>
    ) : (
      <Table.Cell>{decision ? decision.decision : null}</Table.Cell>
    )}

    {viewType === "scheduler" ? (
      <Table.Cell>{decision ? decision.payment_type : null}</Table.Cell>
    ) : (
      <Table.Cell>
        {appointment_time
          ? moment(appointment_time).format("MMM Do, YYYY - hh:mm:ss a")
          : "N/A"}
      </Table.Cell>
    )}
  </Table.Row>
);

export default DashboardListItem;
