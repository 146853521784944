import apollo from "../apis/apollo";
import history from "../history";

export const login = (username) => ({
  type: "LOGIN",
  payload: { username, auth: true },
});

export const startLogin = (username, password) => async (dispatch) => {
  try {
    const response = await apollo.post("/api/auth/signin", {
      username,
      password,
    });
    if (response.status === 200) {
      //   console.log("Login successful!");
      localStorage.setItem("token", response.data.accessToken);
      localStorage.setItem("username", JSON.stringify(response.data.username));
      //   console.log(response.data);
      dispatch({ type: "LOGIN", payload: response.data });
      history.push("/dashboard");
    }
  } catch (err) {
    console.log("There was an error:");
    // console.log(err.response.data);
  }
};

export const startLogout = () => async (dispatch) => {
  try {
    const username = localStorage.getItem("username");
    const response = await apollo.post("/api/auth/logout", {
      username,
    });
    if (response.status === 200) {
      console.log("Logout successful!");
      localStorage.clear();
      dispatch({ type: "LOGOUT" });
      history.push("/login");
    }
  } catch (err) {
    localStorage.clear();
    console.log("There was an error:");
    // console.log(err.response.data);
  }
};
