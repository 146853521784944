const semgOptions = [
  {
    key: "low",
    text: "Low",
    value: "low",
  },
  {
    key: "medium",
    text: "Medium",
    value: "medium",
  },
  {
    key: "high",
    text: "High",
    value: "high",
  },
];

export default semgOptions;
