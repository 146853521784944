const INITIAL_STATE = {
  payment_list: [],
};

const paymentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "FETCH_PAYMENTS":
      let payment_options = [];
      action.payload.payment.forEach((element) => {
        return payment_options.push({
          key: element.name,
          text: element.name,
          value: element.name,
        });
      });
      return {
        ...state,
        payment_list: payment_options,
      };

    default:
      return state;
  }
};

export default paymentReducer;
