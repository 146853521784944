import apollo from "../apis/apollo";
import history from "../history";
import { startLogout } from "./auth";

export const fetchClients = () => async (dispatch) => {
  try {
    const response = await apollo.get("/api/pms");

    dispatch({ type: "FETCH_CLIENTS", payload: response.data });
  } catch (error) {
    // console.log(error);
    // localStorage.clear();
    // history.push("/login");
    dispatch(startLogout());
  }
};

export const fetchClient = (id) => async (dispatch) => {
  try {
    const response = await apollo.get(`/api/pm/${id}`);
    dispatch({ type: "FETCH_CLIENT", payload: response.data });
  } catch (error) {
    dispatch(startLogout());
  }
};

export const editClient = (id, data) => async (dispatch) => {
  try {
    const response = await apollo.patch(`/api/pm/${id}`, data);
    dispatch({ type: "EDIT_CLIENT", payload: response.data });

    // history.push(`/detail/${id}`);
    history.go(0);
  } catch (error) {
    console.log(error);
    dispatch(startLogout());
  }
};

export const deleteClient = (id) => async (dispatch) => {
  try {
    const response = await apollo.delete(`/api/pm/${id}`);
    dispatch({ type: "DELETE_CLIENT", payload: response.data });
    history.push("/dashboard");
  } catch (error) {
    dispatch(startLogout());
  }
};

// export const loadingClients = () => async (dispatch) => {
//   try {
//     dispatch({ type: "LOADING_CLIENTS" });
//   } catch (error) {

//     dispatch(startLogout());
//   }
// };
