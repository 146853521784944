import apollo from "../apis/apollo";
import { startLogout } from "./auth";

export const getEventReport = (event_id) => async (dispatch) => {
  try {
    const response = await apollo.get("/api/reports/event", {
      params: { event_id },
    });
    if (response.status === 200) {
      dispatch({ type: "FETCH_EVENT_REPORT", payload: response.data.results });
    }
  } catch (err) {
    console.log("There was an error getting report data. Could be due to AdBlocker");
    // dispatch(startLogout());

    console.log(err);
  }
};

export const getEventScreenerReport = (event_id) => async (dispatch) => {
  try {
    const response = await apollo.get("/api/reports/eventscreener", {
      params: { event_id },
    });
    if (response.status === 200) {
      dispatch({
        type: "FETCH_EVENT_SCREENER_REPORT",
        payload: response.data.results,
      });
    }
  } catch (err) {
    console.log("There was an error getting report data.");
    dispatch(startLogout());
    // console.log(err.response.data);
  }
};

export const getScreenerReport = (event_id) => async (dispatch) => {
  try {
    const response = await apollo.get("/api/reports/screener", {
      params: { event_id },
    });
    if (response.status === 200) {
      dispatch({
        type: "FETCH_SCREENER_REPORT",
        payload: response.data.results,
      });
    }
  } catch (err) {
    console.log("There was an error getting report data.");
    dispatch(startLogout());
    // console.log(err.response.data);
  }
};

export const getPaymentReport = (event_id) => async (dispatch) => {
  try {
    const response = await apollo.get("/api/reports/payment", {
      params: { event_id },
    });
    if (response.status === 200) {
      dispatch({
        type: "FETCH_PAYMENT_REPORT",
        payload: response.data.results,
      });
    }
  } catch (err) {
    console.log("There was an error getting report data.");
    dispatch(startLogout());
    // console.log(err.response.data);
  }
};

export const getEventsList = () => async (dispatch) => {
  try {
    const response = await apollo.get("/api/eventlist");
    if (response.status === 200) {
      dispatch({
        type: "FETCH_EVENTS_LIST",
        payload: response.data,
      });
    }
  } catch (err) {
    console.log("There was an error getting report data.");
    dispatch(startLogout());
    // console.log(err.response.data);
  }
};
