import React from "react";
import { Segment, Container, Divider } from "semantic-ui-react";

const Footer = () => (
  <Segment vertical style={{ margin: "5em 0em 0em", padding: "5em 0em" }}>
    <Divider section />
    <Container text textAlign="center">
      <p>Made with lots of ☕ and ❤️ in Singapore</p>
    </Container>
  </Segment>
);

export default Footer;
