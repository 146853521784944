const INITIAL_STATE = {
  event_report: [],
  event_screener_report: [],
  screener_report: [],
  payment_report: [],
  event_list: [],
};

const reportReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "FETCH_EVENT_REPORT":
      return {
        ...state,
        event_report: action.payload,
      };

    case "FETCH_EVENT_SCREENER_REPORT":
      return {
        ...state,
        event_screener_report: action.payload,
      };

    case "FETCH_SCREENER_REPORT":
      return {
        ...state,
        screener_report: action.payload,
      };

    case "FETCH_PAYMENT_REPORT":
      return {
        ...state,
        payment_report: action.payload,
      };

    case "FETCH_EVENTS_LIST":
      let event_options = [];
      action.payload.results.forEach((element) => {
        return event_options.push({
          key: element.event_name,
          text: element.event_name,
          value: element.event_name,
        });
      });
      return {
        ...state,
        event_list: event_options,
      };

    default:
      return state;
  }
};

export default reportReducer;
